import React from "react";
import { mapping, SmallAvatar, ButtonSolid } from "@aim-mf/styleguide";
import { ChangePasswordPopup } from "./changePasswordPopup";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.user.email,
      display_name: this.props.user.display_name,
      changePassword: false,
      changePasswordInput: { current: "", new1: "", new2: "" },
      passwordError: {
        currentEmpty: false,
        currentWrong: this.props.pwChangeError !== "",
        newMismatch: false,
        newEmpty: false,
        newInvalid: false,
      },
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.user.email !== this.props.user.email) {
      this.setState({ email: nextProps.user.email });
    }
    if (nextProps.user.display_name !== this.props.user.display_name) {
      this.setState({ email: nextProps.user.display_name });
    }
    if (nextProps.pwChangeError !== this.props.pwChangeError) {
      let passwordError = this.state.passwordError;
      passwordError.currentWrong = nextProps.pwChangeError !== "";
      this.setState({ passwordError: passwordError });
    }
  }

  render() {
    return (
      <div style={{ margin: "35px 0 0 162px" }}>
        <div
          style={{
            filter: this.state.changePassword ? "blur(5px)" : "blur(0px)",
          }}
        >
          <div style={mapping["heading/h3/lightleft"]}>My Profile</div>
          <div style={PanelStyle}>
            <div style={{ position: "absolute", right: "15px", top: "15px" }}>
              <ButtonSolid
                name={"Save Change"}
                width={"130px"}
                clickEvent={this.UserProfileUpdate}
              />
            </div>
            <div style={{ ...ContainerStyle, width: "20rem", padding: "0" }}>
              <div style={AvatarBG}>
                <SmallAvatar
                  size={"18.5rem"}
                  initial={this.props.user.display_name
                    .split(" ")
                    .map((a) => {
                      return a[0].toUpperCase();
                    })
                    .join("")}
                />
              </div>
            </div>
            <div>
              <div
                style={{ ...ContainerStyle, width: "68rem", marginTop: "3rem" }}
              >
                <div
                  style={{
                    ...mapping["heading/h4/lightleft"],
                    marginBottom: "1rem",
                    fontSize: "30px",
                  }}
                >
                  {this.props.user.display_name}
                </div>
                <div style={mapping["inlinetext/Italicized/lightleft"]}>
                  {this.props.user.title === ""
                    ? "Title not assigned"
                    : this.props.user.title}
                </div>
              </div>
              <div
                style={{
                  backgroundColor: mapping["Field_BG_Color"],
                  height: "3px",
                  width: "68rem",
                  marginTop: "3rem",
                  marginBottom: "1rem",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={ContainerStyle}>
                  <InputTextField
                    title={"Username"}
                    value={this.state.display_name}
                    onCustomChange={(value) => {
                      this.setState({ display_name: value });
                    }}
                  />
                  <InputTextField
                    title={"Email"}
                    value={this.state.email}
                    onCustomChange={(value) => {
                      this.setState({ email: value });
                    }}
                  />
                  <div style={TextLineStyle}>
                    <StaticTextField
                      marginTop={"0rem"}
                      title={"Password"}
                      value={"********"}
                      width={"25rem"}
                    />
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                    <div
                      className={"div_hover"}
                      style={{
                        ...mapping["inlinetext/Italicized/lightleft"],
                        fontSize: "12px",
                        paddingRight: "4px",
                        textAlign: "left",
                        color: "#00BEFF",
                      }}
                      onClick={this.handleChangePassword}
                    >
                      Change
                    </div>
                  </div>
                </div>
                <div style={ContainerStyle}>
                  <StaticTextField
                    title={"Title"}
                    value={
                      this.props.user.title === ""
                        ? "Title not assigned"
                        : this.props.user.title
                    }
                  />
                  <StaticTextField
                    title={"Access Level"}
                    value={this.props.accessLevel[this.props.info.access_level]}
                  />
                  <StaticTextField
                    title={"Activity"}
                    value={
                      this.props.info.is_active === true
                        ? "Active"
                        : "Not active"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.changePassword && (
          <ChangePasswordPopup
            pwChangeError={this.props.pwChangeError}
            cancelFunction={this.handleChangePasswordCancel}
            submitFunction={this.handleChangePasswordSubmit}
            passwordError={this.state.passwordError}
            passwordChanged={this.props.passwordChanged}
          />
        )}
      </div>
    );
  }
  handleChangePassword = () => {
    this.setState({
      changePassword: true,
      changePasswordInput: { current: "", new1: "", new2: "" },
      passwordError: {
        currentEmpty: false,
        currentWrong: this.props.pwChangeError !== "",
        newMismatch: false,
        newEmpty: false,
        newInvalid: false,
      },
    });
  };
  handleChangePasswordCancel = () => {
    this.setState({ changePassword: false });
  };
  handleChangePasswordSubmit = (pwSubmit) => {
    const passwordRegex = new RegExp(
      "^(^(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$)?(^(?=.*d)(?=.*[a-z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*d)(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?$"
    );
    const passwordValidator = (value) =>
      !value
        ? "Field can not be empty"
        : passwordRegex.test(value)
        ? "valid"
        : "Please enter a valid password.";

    let currentEmpty = false;
    let currentWrong = this.props.pwChangeError !== "";
    let newMismatch = false;
    let newEmpty = false;
    let newInvalid = false;
    if (
      pwSubmit.new1 !== pwSubmit.new2 ||
      pwSubmit.current === "" ||
      pwSubmit.new1 === "" ||
      pwSubmit.new2 === "" ||
      passwordValidator(pwSubmit.new1) !== "valid" ||
      currentWrong === true
    ) {
      if (pwSubmit.new1 !== pwSubmit.new2) {
        newMismatch = true;
      }
      if (pwSubmit.current === "") {
        currentEmpty = true;
      }
      if (pwSubmit.new1 === "" || pwSubmit.new2 === "") {
        newEmpty = true;
      }
      if (passwordValidator(pwSubmit.new1) !== "valid") {
        newInvalid = true;
      }
      this.setState({
        passwordError: {
          currentEmpty: currentEmpty,
          currentWrong: currentWrong,
          newMismatch: newMismatch,
          newEmpty: newEmpty,
          newInvalid: newInvalid,
        },
      });
    } else {
      this.setState({
        changePasswordInput: {
          current: pwSubmit.current,
          new1: pwSubmit.new1,
          new2: pwSubmit.new2,
        },
        passwordError: {
          currentEmpty: false,
          currentWrong: this.props.pwChangeError !== "",
          newMismatch: false,
          newEmpty: false,
          newInvalid: false,
        },
      });

      this.props.handleChangePassword({
        old_password: pwSubmit.current,
        new_password: pwSubmit.new1,
        repeat_password: pwSubmit.new2,
      });
    }
  };
  UserProfileUpdate = () => {
    if (
      this.state.email !== this.props.user.email ||
      this.state.display_name !== this.props.user.display_name
    ) {
      this.props.handleUpdateUser({
        display_name: this.state.display_name,
        email: this.state.email,
      });
    }
  };
}
// email: "test_tenant_admin@test.com", display_name: "Test Tenant Admin", tenant: "test", title: "",
const PanelStyle = {
  position: "relative",
  float: "left",
  backgroundColor: "#2F3B52",
  height: "auto",
  width: "100rem",
  borderRadius: "10px",
  margin: "0.5rem",
  marginTop: "2rem",
  padding: "4rem",
  display: "flex",
  flexDirection: "row",
};
const ContainerStyle = {
  height: "auto",
  width: "32rem",
  marginRight: "4rem",
  // marginTop: "4rem",
  // marginBottom: "3rem",
  // paddingTop: "3rem",
  paddingBottom: "1rem",
  display: "flex",
  flexDirection: "column",
  // backgroundColor: "lightblue",
};
const AvatarBG = {
  borderRadius: "50%",
  backgroundColor: mapping["Color_Card_Shadow"],
  height: "20rem",
  width: "20rem",
  padding: "0.75rem",
};
const TextLineStyle = {
  height: "46px",
  width: "32rem",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginTop: "1rem",
  // backgroundColor: "red",
};
const FieldTitle = (props) => {
  return (
    <div
      style={{
        height: "16px",
        width: "8rem",
        display: "flex",
      }}
    >
      <div style={mapping["paragraph/default/lightleft"]}>{props.value} : </div>
    </div>
  );
};
const StaticTextField = (props) => {
  return (
    <div
      style={{
        ...TextLineStyle,
        marginTop: props.marginTop ? props.marginTop : "1rem",
        width: props.width ? props.width : "32rem",
      }}
    >
      <FieldTitle value={props.title} />
      <div
        style={{
          ...mapping["paragraph/default/lightleft"],
          fontSize: "15px",
          height: "15px",
          paddingLeft: "4px",
        }}
      >
        {props.value}
      </div>
    </div>
  );
};
const InputFieldStyle = {
  height: mapping["Field_Height"],
  width: "20rem",
  borderWidth: "0px",
  backgroundColor: mapping["Field_BG_Color"], //["Color_BG_03_trans"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};
const InputTextField = (props) => {
  return (
    <div
      style={{
        ...TextLineStyle,
        marginTop: props.marginTop ? props.marginTop : "1rem",
        width: props.width ? props.width : "32rem",
      }}
    >
      <FieldTitle value={props.title} />
      <input
        value={props.value}
        style={InputFieldStyle}
        onChange={(event) => {
          props.onCustomChange(event.target.value);
        }}
      />
    </div>
  );
};

export { UserProfile };
