import React from "react";
import { UserProfile } from "./page/userProfile";
import { fetchAdminAPIWithPermission } from "@aim-mf/api";
import { AuthCheckAndAutofix } from "@aim-mf/styleguide";

const initialState = {
  loadingAccessLevel: true,
  passwordChanged: false,
  accessLevel: {},
  pwChangeError: "",
};

function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    loadingAccessLevel,
    accessLevel,
    passwordChanged,
    pwChangeError,
  } = state;

  let user = JSON.parse(sessionStorage.getItem("CurrentUser"));
  let info = JSON.parse(sessionStorage.getItem("MyProfile"));

  React.useEffect(() => {
    if (loadingAccessLevel) {
      const subscription = fetchAdminAPIWithPermission(
        "auth/profile/access_level"
      ).subscribe(
        (results) => {
          ////console.log("in post api results");
          dispatch({ type: "loadAccessLevel", data: results.data });
        },
        (err) => {
          dispatch({ type: "notLoadingAccessLevel" });
          //console.log(err); // eslint-disable-line
          // //console.log(err.response.data); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            handleAccessLevelRequest();
          });
        }
      );

      return () => subscription.unsubscribe();
    }
  }, [loadingAccessLevel]);

  const handleAccessLevelRequest = () => {
    dispatch({ type: "loadingAccessLevel" });
  };

  const handleChangePassword = (data) => {
    const subscription = fetchAdminAPIWithPermission("auth/profile/password", {
      data: data,
    }).subscribe(
      (results) => {
        //console.log("password changed successfully");
        dispatch({ type: "passwordChanged" });
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        // //console.log(err.response.data); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          handleChangePassword(data);
        });
        dispatch({
          type: "passwordChangeError",
          data: err.response.data.detail,
        });
      }
    );

    return () => subscription.unsubscribe();
  };

  const handleUpdateProfile = (data) => {
    ////console.log(data);
    const subscription = fetchAdminAPIWithPermission("auth/profile/update", {
      data: data,
    }).subscribe(
      (results) => {
        //console.log(results)
        let newUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
        newUser.email = results.data.email;
        newUser.display_name = results.data.display_name;
        let newInfo = JSON.parse(sessionStorage.getItem("CurrentUser"));
        newInfo.email = results.data.email;
        newInfo.display_name = results.data.display_name;
        sessionStorage.setItem("CurrentUser", JSON.stringify(newUser));
        sessionStorage.setItem("MyProfile", JSON.stringify(newInfo));
        user = newUser;
        info = newInfo;
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        // //console.log(err.response.data); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          handleUpdateProfile(data);
        });
      }
    );

    return () => subscription.unsubscribe();
  };

  return (
    <UserProfile
      user={user}
      info={info}
      accessLevel={accessLevel}
      handleChangePassword={handleChangePassword}
      handleUpdateUser={handleUpdateProfile}
      passwordChanged={passwordChanged}
      pwChangeError={pwChangeError}
    />
  );
  function reducer(state = initialState, action) {
    switch (action.type) {
      case "loadingAccessLevel":
        return { ...state, loadingAccessLevel: true };
      case "notLoadingAccessLevel":
        return { ...state, loadingAccessLevel: false };
      case "loadAccessLevel":
        return {
          ...state,
          accessLevel: action.data,
          loadingAccessLevel: false,
        };
      case "passwordChanged":
        return {
          ...state,
          passwordChanged: true,
        };
      case "passwordChangeError":
        return {
          ...state,
          pwChangeError: action.data,
        };
      default:
        throw Error(`Unknown action type '${action.type}'`);
    }
  }
}

export default App;
