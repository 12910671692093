import React from "react";
import { mapping, ButtonSolid, ConfirmationPopup } from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

// this part i think can use AutoComplete from styleguide

class ChangePasswordPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "",
      new1: "",
      new2: "",
    };
  }

  render() {
    //console.log(this.props.passwordChanged)
    return (
      <div>
        <div style={RiskCreationFormWrapStyle}>
          <div style={RiskCreationFormStyle}>
            <div style={formBgStyle}>
              <div
                style={{
                  ...mapping["heading/h5/lightleft"],
                  marginBottom: "2rem",
                }}
              >
                Change your password
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <PasswordInputField
                  title={"Current password: "}
                  value={this.state.current}
                  onChange={(event) => {
                    this.setState({ current: event.target.value });
                  }}
                />
                <div style={{ height: "1rem" }}>
                  <WarningMsg
                    value={this.WarningMsgCurrent(this.props.passwordError)}
                  />
                </div>
                <PasswordInputField
                  title={"New password: "}
                  value={this.state.new1}
                  onChange={(event) => {
                    this.setState({ new1: event.target.value });
                  }}
                />
                <div style={{ height: "1rem" }}>
                  <WarningMsg
                    value={this.WarningMsgNew1(this.props.passwordError)}
                  />
                </div>
                <PasswordInputField
                  title={"Confirm new password: "}
                  value={this.state.new2}
                  onChange={(event) => {
                    this.setState({ new2: event.target.value });
                  }}
                />
                <div style={{ height: "1rem" }}>
                  <WarningMsg
                    value={this.WarningMsgNew(this.props.passwordError)}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "5rem",
                }}
              >
                <ButtonSolid
                  name={"Cancel"}
                  color={"Secondary"}
                  width={"80px"}
                  height={"36px"}
                  clickEvent={this.props.cancelFunction}
                />
                <ButtonSolid
                  name={"Confirm"}
                  color={"Primary"}
                  width={"80px"}
                  height={"36px"}
                  clickEvent={() => {
                    this.props.submitFunction(this.state);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.props.passwordChanged && (
          <ConfirmationPopup
            title={"Password Change Successfully"}
            content={
              "your password has been changed, please login with the new password again"
            }
            cancelFunction={() => {
              navigateToUrl("/login");
            }}
            confirmFunction={() => {
              navigateToUrl("/login");
            }}
          />
        )}
      </div>
    );
  }
  WarningMsgCurrent = (passwordError) => {
    const passwordRegex = new RegExp(
      "^(^(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$)?(^(?=.*d)(?=.*[a-z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*d)(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?$"
    );
    const passwordValidator = (value) =>
      !value
        ? "Field can not be empty"
        : passwordRegex.test(value)
        ? "valid"
        : "Please enter a valid password.";

    if (passwordError.currentEmpty) {
      return "Please enter your current password.";
    } else if (passwordError.currentWrong) {
      return "Incorrect password, please re-enter.";
    } else if (
      passwordValidator(this.state.current) !== "valid" &&
      this.state.current !== ""
    ) {
      return "Please enter a valid current password.";
    }
  };
  WarningMsgNew = (passwordError) => {
    if (passwordError.newEmpty) {
      return "Please enter your new password.";
    } else if (
      this.state.new1 !== "" &&
      this.state.new2 !== "" &&
      this.state.new1 !== this.state.new2
    ) {
      return "Passwords do not match, please check again.";
    }
  };
  WarningMsgNew1 = (passwordError) => {
    const passwordRegex = new RegExp(
      "^(^(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$)?(^(?=.*d)(?=.*[a-z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*d)(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?$"
    );
    const passwordValidator = (value) =>
      !value
        ? "Field can not be empty"
        : passwordRegex.test(value)
        ? "valid"
        : "Please enter a valid password.";

    if (
      passwordError.newInvalid ||
      (passwordValidator(this.state.new1) !== "valid" && this.state.new1 !== "")
    ) {
      return "Please enter a valid new password.";
    }
  };
}

const InputFieldStyle = {
  height: mapping["Field_Height"],
  width: "100%",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};
const formBgStyle = {
  height: "fit-content",
  width: "400px",
  padding: "3rem 1.5rem 3rem 1.5rem",
  backgroundColor: mapping["Color_Extra_Popup_BG"],
  //display: "inline-flex",
  position: "relative",
};
const RiskCreationFormStyle = {
  position: "relative",
  left: "-50%",
};
const RiskCreationFormWrapStyle = {
  position: "absolute",
  left: "50%",
  top: "13.3rem",
};
const FieldTitle = (props) => {
  return (
    <div
      style={{
        height: "16px",
        width: "100%",
        marginBottom: "12px",
      }}
    >
      <div style={mapping["paragraph/default/lightleft"]}>{props.value} </div>
    </div>
  );
};
const PasswordInputField = (props) => {
  return (
    <div style={{ marginTop: "2.5rem", marginBottom: "0.5rem" }}>
      <FieldTitle value={props.title} />
      <input
        type={"password"}
        value={props.value}
        style={InputFieldStyle}
        onChange={props.onChange}
      />
    </div>
  );
};
const WarningMsg = (props) => {
  return (
    <div
      style={{
        ...mapping["inlinetext/Italicized/lightleft"],
        fontSize: "14px",
        color: mapping["Color_Basic_Danger"],
      }}
    >
      {props.value}
    </div>
  );
};

export { ChangePasswordPopup };
